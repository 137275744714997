<script>
import {
    ArrowUpIcon
} from 'vue-feather-icons';
import {
    Carousel,
    Slide
} from 'vue-carousel';

import Navbar from "@/components/navbar0";

import Footer from "@/components/footer";
import store from "@/store/store";

/**
 * Shop-cart component
 */
export default {
    data() {
        return {
            counter: 0,

            cart_price: {
              subtotal: 0,
              discount: 0,
              total: 0,
            },

            resumes: [],

          in_sql: {
              user_id:0,
              resume_id:0,
              cart_id:0,
          },

          add_cash_sql_in: {
            user_email: '',
            user_id: 0,
            service_name: 'Покупка резюме Skillrank',
            description: 'Покупка резюме Skillrank',
            price: 0,
            price_rub: 0,
            quantity: 1,
          },

        }
    },
    components: {
        Navbar,

        Footer,
        Carousel,
        Slide,
        ArrowUpIcon
    },

    mounted() {
      this.getResumesInCart();
    },

    methods: {
        increase(val) {
            this.list.map(product => {
                if (product.id === val) {
                    product.qty += 1;
                }
            });
        },
        decrement(val) {
            this.list.map(product => {
                if (product.id === val) {
                    while (product.qty > 0) {
                        product.qty -= 1;
                    }
                }

            });
        },

      removeResumeFromCart(ix) {

        this.in_sql.cart_id = this.resumes[ix].cart_id;
        this.in_sql.resume_id = this.resumes[ix].resume_id;
        this.in_sql.user_id = store.state.user_id;


        this.axios({
          method: 'post',
          url: '' + this.$hostname + '/resume-cart/remove-resumes?access_token=' + store.state.token,
          data: this.in_sql,
        }).then((res) => {
          if (res.data.status === "ok") {
            this.resumes.splice(ix,1);
          }
        });

      },

      getResumesInCart() {


        this.in_sql.user_id = store.state.user_id;

        this.axios({
          method: 'post',
          url: '' + this.$hostname + '/resume-cart/get-resumes-in-cart?access_token=' + store.state.token,
          data: this.in_sql,
        }).then((res) => {
          this.resumes = res.data.resumes
          this.cart_price.subtotal = res.data.subtotal;
          this.cart_price.total = this.cart_price.subtotal-this.cart_price.discount;
        });


      },

      payFromTinkov() {

        this.add_cash_sql_in.user_email = store.state.username;
        this.add_cash_sql_in.user_id = store.state.user_id;
        this.add_cash_sql_in.price = parseInt(this.cart_price.total)*100;
        console.log(this.add_cash_sql_in);
        this.axios({
          method: 'post',
          url: '' + this.$hostname + '/payment/init?access_token=' + store.state.token,
          data: this.add_cash_sql_in,
        }).then((res) => {
          console.log(res.data)
          window.open(res.data, '_blank');
        });

      },



    }
}
</script>

<template>
<div>
    <Navbar />

    <section class="section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="table-responsive mt-3 bg-white shadow">
                        <table class="table table-center table-padding mb-0 mt-5">
                            <thead>
                                <tr>
                                    <th class="py-3" style="min-width:20px "></th>
                                    <th class="py-3" style="min-width: 100px;">Имя</th>
                                    <th class="py-3" style="min-width: 300px;">Должность</th>
                                    <th class="text-center py-3" style="min-width: 160px;">Цена</th>
                                    <!--<th class="text-center py-3" style="min-width: 160px;">Qty</th>
                                    <th class="text-center py-3" style="min-width: 160px;">Total</th>-->
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(resume, index) of resumes" :key="index">
                                    <td class="h6"><a @click="removeResumeFromCart(index)" class="text-danger">X</a></td>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <h6 class="mb-0 ml-3">{{resume.resume_fullname}}</h6>
                                        </div>
                                    </td>
                                  <td>
                                    <div class="d-flex align-items-center">
                                      <h6 class="mb-0 ml-3">{{resume.resume_headline}}</h6>
                                    </div>
                                  </td>
                                    <td class="text-center">₽ 10</td>
                                  <!--
                                    <td class="text-center">
                                        <input type="button" value="-" class="minus btn btn-icon btn-soft-primary font-weight-bold" @click="decrement(item.id)">
                                        <input type="text" v-model="item.qty" step="1" min="1" name="quantity" value="2" title="Qty" class="btn btn-icon btn-soft-primary font-weight-bold ml-1">
                                        <input type="button" value="+" class="plus btn btn-icon btn-soft-primary font-weight-bold ml-1" @click="increase(item.id)">
                                    </td>
                                    <td class="text-center font-weight-bold">${{item.total}}.00</td>
                                    -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
            <div class="row">
                <div class="col-lg-8 col-md-6 mt-4 pt-2">
                    <!-- <a href="/search-candidates" class="btn btn-primary">Перейти к поиску</a> -->
                    <!--<a href="javascript:void(0)" class="btn btn-soft-primary ml-2">Update Cart</a>-->
                </div>
                <div class="col-lg-4 col-md-6 ml-auto mt-4 pt-2">
                    <div class="table-responsive bg-white rounded shadow">
                        <table class="table table-center table-padding mb-0">
                            <tbody>
                                <tr>
                                    <td class="h6">Стоимость</td>
                                    <td class="text-center font-weight-bold">₽ {{cart_price.subtotal}}</td>
                                </tr>
                                <tr>
                                    <td class="h6">Скидка</td>
                                    <td class="text-center font-weight-bold">₽ {{cart_price.discount}}</td>
                                </tr>
                                <tr class="bg-light">
                                    <td class="h6">Всего</td>
                                    <td class="text-center font-weight-bold">₽ {{cart_price.total}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="mt-4 pt-2 text-right">
                      <a @click="payFromTinkov" class="btn btn-primary">Оплатить</a>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- End -->
    <!--end section-->
    <Footer />
    <!-- Footer End
    <Switcher />
    -->
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>
